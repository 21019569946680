import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
//import "@phila/phila-ui/src/styles/all.scss";
import * as PhilaUi from "@phila/phila-ui";
import VueSimpleAlert from "vue-simple-alert";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  length,
  min,
  numeric,
  double,
  confirmed
} from "vee-validate/dist/rules";
import { withValidation } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

const VeeTextbox = withValidation(PhilaUi.Textbox);
const VeeDropdown = withValidation(PhilaUi.Dropdown);
const VeeTextarea = withValidation(PhilaUi.TextArea);
const VeeRadio = withValidation(PhilaUi.Radio);
const VeeCheckbox = withValidation(PhilaUi.Checkbox);
Vue.use(VueSimpleAlert);
Vue.component("VeeTextbox", VeeTextbox);
Vue.component("VeeDropdown", VeeDropdown);
Vue.component("VeeTextarea", VeeTextarea);
Vue.component("VeeRadio", VeeRadio);
Vue.component("VeeCheckbox", VeeCheckbox);

extend("numeric", {
  ...numeric,
  message: "Please enter a number"
});
extend("length", length);
extend("min", min);
extend("min_array_length", {
  validate(value, args) {
    return value.length >= args.length;
  },
  message(value, args) {
    return `Select at least ${args.length}`;
  },
  params: ["length"]
});
extend("account_confirmed", {
  ...confirmed,
  message: "Account Number is different"
});
extend("routing_confirmed", {
  ...confirmed,
  message: "Routing Number is different"
});
extend("required", {
  ...required,
  message: "This field is required"
});
extend("required_status", {
  ...required,
  message: "This field is required for the status verification check."
});
extend("required_cellmessage", {
  ...required,
  message: "This field is required"
});
extend("required_emailmessage", {
  ...required,
  message: "This field is required"
});
extend("email", {
  ...email,
  message:
    "Please enter a valid email address (for example: MyName@MyEmail.com)"
});
extend("doubleCurrency", {
  ...double,
  message: "Please enter a valid amount with 2 decimals (for example: 99.99)"
});
extend("phone", {
  message:
    "Please enter a valid phone number (for example: 123-456-7890 or 9876543210)",
  validate(value) {
    return new Promise(resolve => {
      const isValid = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        value
      );
      resolve({ valid: isValid });
    });
  }
});
Vue.use(PhilaUi);
const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = true;
Vue.config.devtools = !isProduction;

new Vue({
  router,
  store,
  mounted: function() {
    // fix for IE 11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.addEventListener("hashchange", this.hashChangeHandler);
    }
  },
  destroyed: function() {
    // fix for IE 11
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.removeEventListener("hashchange", this.hashChangeHandler);
    }
  },
  methods: {
    hashChangeHandler: function() {
      var target = window.location.hash;
      this.$router.push(target.substring(1, target.length));
    }
  },
  render: h => h(App)
}).$mount("#app");

// Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount("#app");
