<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="EduSessionsFrm"
              @submit.prevent="handleSubmit"
            >
              <br />
              <h3>
                Rental Improvement Fund - Information Sessions
              </h3>
              <callout type="info">
                Please register for one of the sessions below. As a reminder,
                you must attend a program information session prior to closing a
                Rental Improvement Fund loan. We recommend attending as early as
                possible in the loan application process, since the session will
                cover a range of relevant information and provide a good
                opportunity to ask questions.<br /><br />

                <u>Virtual Sessions</u><br />
                All virtual information sessions will be held online. You will
                receive an email confirmation with the URLto join the
                session.<br /><br />
                <u>In-Person Sessions</u><br />
                All in-person information sessions will be held at PHDC's office
                on the 17th floor of 1234 Market Street. Space is limited, so
                you must register beforehand in order to attend.
              </callout>
              <div class="columns">
                <div class="column">
                  <vee-dropdown
                    v-model="sessionType"
                    :options="SessionTypes"
                    name="input-sessiontype"
                    placeholder="Would you like to sign up for a virtual or in-person session?"
                  />
                </div>
              </div>
              <div v-if="sessionType == 'Virtual'">
                <div class="column">
                  <div class="columns">
                    <vee-dropdown
                      v-model="dateDropdownQuery"
                      :options="SessionDates"
                      name="input-dates"
                      placeholder="Select a Session Date"
                      @change="getSessionInfo()"
                    />
                  </div>
                </div>
                <div class="column">
                  <div><b>Start time:</b> {{ virtualStartTime }}</div>
                  <div><b>End time:</b> {{ virtualEndTime }}</div>
                  <div>
                    <b>URL: </b>
                    <a v-bind:href="'//' + zoomLink">{{ zoomLink }}</a>
                  </div>
                </div>
              </div>
              <div v-if="sessionType == 'In-Person'">
                <div class="column">
                  <div class="columns">
                    <vee-dropdown
                      v-model="inPersonDateDropdownQuery"
                      :options="SessionDatesInPerson"
                      name="input-dates-in-person"
                      placeholder="Select a in person Session Date"
                      @change="getSessionInfoInPerson()"
                    />
                  </div>
                </div>
                <div class="column">
                  <div><b>Start time:</b> {{ inPersonStartTime }}</div>
                  <div><b>End time:</b> {{ inPersonEndTime }}</div>
                  <div><b>Meeting Address:</b> {{ meetingAddress }}</div>
                </div>
              </div>
              <!--<div>Date of session list: {{ sessionDates }}</div>
              <div>Record ID: {{ quickbaseRecordID }}</div>-->
              <vee-textbox
                v-model="FirstName"
                name="input-firstName"
                required
                error="This field is required"
                rules="required"
                placeholder="Enter your First Name"
                type="text"
              />
              <vee-textbox
                v-model="LastName"
                name="input-lastName"
                required
                error="This field is required"
                rules="required"
                placeholder="Enter your Last Name"
                type="text"
              />
              <vee-textbox
                v-model="EmailAddress"
                name="input-emailAddress"
                required
                error="This field is required"
                placeholder="Enter your Email"
                rules="email|required_emailmessage"
              />
              <div>
                <div class="column is-2 is-offset-10">
                  <button
                    type="button"
                    :disabled="
                      SubmitStatus.Value === 'PENDING' ||
                        SubmitStatus.Value === 'OK'
                    "
                    class="button is-primary"
                    @click="handleSubmit"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="column">
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING">
                  Submitting...
                </div>
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                  OK
                </div>
                <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                  Error
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import options from "@/utilities/options.js";
import buildEduSessionObject from "@/utilities/get-edu-session-object.js";
import buildEduSessionDatesObject from "@/utilities/get-edu-session-dates-object.js";
import { getPropertyValueOrDefault } from "@/utilities/functions.js";
import axios from "axios";
import { dataObjectToXml } from "@/utilities/functions.js";
import buildEduSubmissionDataObject from "@/utilities/edu-session-submission-object.js";

export default {
  name: "EduSessions",
  mixins: [options],
  data() {
    return {
      sessionDates: null,
      dateDropdownQuery: null,
      inPersonDateDropdownQuery: null,
      virtualStartTime: null,
      virtualEndTime: null,
      inPersonStartTime: null,
      inPersonEndTime: null,
      zoomLink: null,
      SessionDates: [],
      SessionDatesInPerson: [],
      dropdownDates: null,
      inPersonDropdownDates: null,
      quickbaseRecordID: null,
      sessionType: null,
      meetingAddress: "1234 Market Street, Floor 17, Philadelphia PA, 19107"
    };
  },
  methods: {
    async handleSubmit() {
      // Submits the users information to QuickBase
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      const isInputValid = await vData.$refs.form.$parent.validate();
      if (isInputValid) {
        const dataObject = buildEduSubmissionDataObject(vData);
        const xmlDataObject = dataObjectToXml(dataObject);
        const requestObject = {
          table: "bs5rt2xu9",
          action: "API_AddRecord",
          data: xmlDataObject
        };

        axios
          .post(
            "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
            requestObject
          )
          .then(response => {
            console.log(response);
            vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
            window.alert("Your request has been submitted.");
            vData.$router.push({
              name: "Apply"
            });
          });
      } else {
        vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
      }
    },
    getSessionInfo() {
      var vData = this;
      const dataObject = buildEduSessionObject(
        vData.dateDropdownQuery,
        "RIF_GET_EDU_INFO"
      );
      const requestObj = {
        table: "bs5rtm27k",
        data: dataObject
      };
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
          requestObj
        )
        .then(response => {
          const responseObject = getPropertyValueOrDefault(
            ["data", "record", "f"],
            response
          );
          vData.virtualStartTime = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "15")
          );
          vData.virtualEndTime = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "18")
          );
          vData.zoomLink = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "7")
          );
          vData.sessionDates = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "17")
          );
          vData.quickbaseRecordID = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "3")
          );
        });
    },
    getSessionInfoInPerson() {
      var vData = this;
      const dataObject = buildEduSessionObject(
        vData.inPersonDateDropdownQuery,
        "RIF_GET_EDU_INFO"
      );
      const requestObj = {
        table: "bs5rtm27k",
        data: dataObject
      };
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
          requestObj
        )
        .then(response => {
          const responseObject = getPropertyValueOrDefault(
            ["data", "record", "f"],
            response
          );
          vData.inPersonStartTime = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "15")
          );
          vData.inPersonEndTime = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "18")
          );
          //vData.meetingAddress = getPropertyValueOrDefault(
          //["_text"],
          //responseObject.find(i => i._attributes.id === "25")
          //); RE-ENABLE IF YOU WANT TO PULL IN ADDRESS FROM QUICKBASE
          vData.sessionDates = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "24")
          );
          vData.quickbaseRecordID = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "3")
          );
        });
    }
  },
  created() {
    // Gets the dates for the upcoming sessions and adds them to the date selector
    var vData = this;
    const dataObject = buildEduSessionDatesObject(
      "03-01-2023", //passes in variable to grab first entry after 3/1/23
      "RIF_GET_EDU_INFO"
    );
    const requestObj = {
      table: "bs5rtm27k",
      data: dataObject
    };
    axios
      .post(
        "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
        requestObj
      )
      .then(response => {
        //Break down response by data, record, 0, f and set responseObject to it
        const responseObject = getPropertyValueOrDefault(
          ["data", "record", 0, "f"],
          response
        );
        //Print out response to the console. The response can be broken down by section
        console.log(response);
        //Print out responseObject to the console. The responseObject can be broken down by section
        console.log(responseObject);
        // Find value in responseObject by attribute id 17 and 24 and get the date lists
        vData.dropdownDates = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "17")
        );
        vData.inPersonDropdownDates = getPropertyValueOrDefault(
          ["_text"],
          responseObject.find(i => i._attributes.id === "24")
        );
        //Get the dropdownDates text and split to array by semicolon(;) set to SessionsDates array
        this.SessionDates = this.dropdownDates.split(" ; ");
        this.SessionDatesInPerson = this.inPersonDropdownDates.split(" ; ");
      });
  }
};
</script>
