import Vue from "vue";
import VueRouter from "vue-router";
import Apply from "../views/Apply.vue";
import LandlordRepairApplication from "../views/LandlordRepairApplication.vue";
import InspectionRequest from "../views/InspectionRequest.vue";
import FileUpload from "../views/FileUpload.vue";
import Success from "../views/Success.vue";
import EduSessions from "../views/EduSessions.vue";
import BankAccountForm from "../views/BankAccountForm.vue";
import FAQ25Kfor10Y from "../views/25K-10YFAQ.vue";
import FAQ50Kfor15Y from "../views/50K-15YFAQ.vue";
import BankAccountSuccess from "../views/BankAccountSuccess.vue";
import Status from "../views/Status.vue";
import InvoiceUpload from "../views/InvoiceUpload.vue";
import InvoiceUploadSuccess from "../views/InvoiceUploadSuccess.vue";
import FileUploadSuccess from "../views/FileUploadSuccess.vue";
import LoanDetails from "../views/LoanDetails.vue";
import SuccessInspection from "../views/SuccessInspection.vue";
import WorkScopeSuccess from "../views/WorkScopeSuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Apply",
    component: Apply
  },
  {
    path: "/WorkScopeSuccess",
    name: "WorkScopeSuccess",
    component: WorkScopeSuccess
  },
  {
    path: "/WorkscopeForm/:loanId",
    name: "WorkscopeForm",
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WorkscopeForm.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/SuccessInspection",
    name: "SuccessInspection",
    component: SuccessInspection
  },
  {
    path: "/loandetails",
    name: "LoanDetails",
    props: true,
    component: LoanDetails
  },
  {
    path: "/landlordrepairapplication",
    name: "LandlordRepairApplication",
    component: LandlordRepairApplication
  },
  {
    path: "/inspectionrequest/:loanId",
    name: "InspectionRequest",
    props: true,
    component: InspectionRequest
  },
  {
    path: "/fileupload/:profileId",
    name: "FileUpload",
    props: true,
    component: FileUpload
  },
  {
    path: "/success",
    name: "Success",
    component: Success
  },
  {
    path: "/InformationSessions",
    name: "InformationSessions",
    props: true,
    component: EduSessions
  },
  {
    path: "/BankAccountForm/:recordId",
    name: "BankAccountForm",
    props: true,
    component: BankAccountForm
  },
  {
    path: "/FAQ25K-10Y",
    name: "FAQ25K-10Y",
    props: true,
    component: FAQ25Kfor10Y
  },
  {
    path: "/FAQ50K-15Y",
    name: "FAQ50K-15Y",
    props: true,
    component: FAQ50Kfor15Y
  },
  {
    path: "/BankAccountSuccess",
    name: "BankAccountSuccess",
    props: true,
    component: BankAccountSuccess
  },
  {
    path: "/Status",
    name: "Status",
    props: true,
    component: Status
  },
  {
    path: "/InvoiceUpload/:profileId",
    name: "InvoiceUpload",
    props: true,
    component: InvoiceUpload
  },
  {
    path: "/InvoiceUploadSuccess",
    name: "InvoiceUploadSuccess",
    component: InvoiceUploadSuccess
  },
  {
    path: "/FileUploadSuccess",
    name: "FileUploadSuccess",
    component: FileUploadSuccess
  }
];

const router = new VueRouter({
  routes
});

export default router;
